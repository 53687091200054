<template>
  <v-data-table
    v-model="selectedItem"
    @click:row="selectRow"
    calculate-widths
    :custom-sort="dataSort"
    dense
    :headers="headers"
    :items="items"
    :items-per-page="25"
    :item-class="(item) => highlightItem(item)"
    :key="dataKey"
    :footer-props="{
      itemsPerPageOptions: [10, 25, 50, -1],
    }"
    :page.sync="page"
    :search="search"
    single-select
    sort-by="version"
    :sort-desc="true"
    class='px-3'
  >
    <template v-slot:top>
      <v-row no-gutters>
        <v-col cols=12 sm=5 align-self="center">
          <span class="font-weight-thin primary--text">
            {{ labels.actions }}
          </span>
          <span
            v-for="action in actions"
            :key=action.name
          >
            <!-- TODO: split into a new component -->
            <!-- Dialog shown for modal actions (comment+certify+showBE) -->
            <v-dialog
              v-if="action.modal && action.allowed"
              width="50vw"
              class="dialog"
            >
              <template v-slot:activator="{ on: onDialog, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                      v-bind="attrs"
                      v-on="{ ...onTooltip, ...onDialog }"
                      icon
                      class="action-btn"
                      :color="action.color || 'primary'"
                      small
                      :disabled="disableButton(versionItem, action)"
                    >
                      <v-icon
                        :color="action.color || 'primary'"
                        dark
                        v-text="action.icon"
                      />
                    </v-btn>
                  </template>
                  <span>{{ action.name }}</span>
                </v-tooltip>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-title class="headline primary white--text">
                    {{ action.name }}
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      :prefix="backend ? labels.bev : labels.fev"
                      :value="versionItem.version"
                      :prepend-icon="'$folder'"
                      readonly
                    />
                    <!-- Show chips when modal is ShowBE (item is a FEV) -->
                    <div v-if="action.name === labels.bev">
                      <v-chip
                        v-for="(bev, ix) in getBackendVersionsForFrontend(versionItem.version)"
                        :key="ix"
                        color="primary"
                      >
                        {{ bev }}
                      </v-chip>
                    </div>
                    <div v-else-if="action.name === labels.certify">
                      <v-text-field
                        label="CSHA"
                        :value=versionItem.csha
                        :prepend-icon="'$csha'"
                        :messages="labels.cshaInfo"
                        filled readonly
                      ></v-text-field>
                      <v-select
                        v-model="formInput"
                        :items="certificationAuthorities"
                        item-text="name"
                        item-value="name"
                        label="Entity"
                        :prepend-icon="'$ca'"
                      ></v-select>
                    </div>
                    <v-text-field
                      v-else
                      :label=labels.comment
                      :prepend-icon="'$comment'"
                      placeHolder="Enter your comment"
                      counter
                      maxlength="100"
                      v-model="formInput"
                    />
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      text
                      @click="dialog.value = false; formInput = null"
                    >
                      {{ labels.close }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      v-if="action.action"
                      @click.prevent="action.action(
                        versionItem,
                        action.param,
                        formInput,
                        backend,
                      ); dialog.value = false; formInput = null"
                    >
                      {{ labels.confirm }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <!-- Non-modal actions (deploy/update/remove) -->
            <v-tooltip v-else left>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click.prevent="action.action(versionItem, action.param);"
                  icon
                  small
                  :color="action.color || 'primary'"
                  class="action-btn"
                  v-on="on"
                  :disabled="disableButton(versionItem, action)"
                >
                  <v-icon
                    :color="action.color || 'primary'"
                    dark
                    v-text="action.icon"
                  />
                </v-btn>
              </template>
              <span>{{ action.name }}</span>
            </v-tooltip>
          </span>
          <v-slider
            v-show="scale"
            v-model="replicas"
            :hint="labels.replicas"
            persistent-hint
            thumb-color="accent"
            thumb-label="always"
            align-items="baseline"
            min=1
            max=10
          >
            <template v-slot:append>
              <v-btn
                @click="$emit('scale', replicas)"
                color="primary"
                outlined
                text
              >
                {{ labels.apply }}
              </v-btn>
            </template>
          </v-slider>
        </v-col>
        <v-spacer />
        <v-text-field
          v-model="search"
          :prepend-icon="'$search'"
          label="Filter"
          single-line
          class="col-12 col-md-7 ma-0"
          hide-details
        />
      </v-row>
    </template>

    <template v-slot:header.lglib-version="{ header }">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ header.text }}
            <v-icon
              @click.prevent="$emit('toggle-incompatible')"
              color="primary"
              class="mx-1"
              v-text="hidden ? '$incompatible' : '$iris'"
            />
            <v-chip
              v-if="hidden"
              color="yellow"
              label
              small pill
            >
              {{ hidden }} hidden
            </v-chip>
        </span>
        </template>
        <span>{{ labels.showCompatible }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.version="{ item }">
      <span v-if="backend">{{ item.build }}-</span><span
      class="font-weight-bold">{{ item.version }}</span>
      <v-badge
        v-if="highlightItem(item)"
        :content="getInstalledLabel(item)"
        color="secondary"
        inline
      />
    </template>

    <template v-slot:item.frontend="{ item }">
      {{ getAssociatedFrontend(item) }}
    </template>

    <template v-slot:item.csha="{ value }">
      <v-chip
        :color="value ? 'accent' : 'error'"
        dark label outlined
        @click.prevent="copyToClipboard(value)"
      >
        <v-icon left v-if="!value" v-text="'$warning'" />
        <span :class="expandCsha ? '' : 'text-truncate'" ref="textToCopy">
          {{ value ? value : labels.unknown }}
        </span>
      </v-chip>
    </template>

    <template v-slot:item.lglib-version="{ item }">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            :color="item.isCompatible ? 'accent' : 'warning'"
            v-bind="attrs"
            v-on="on"
            dark label outlined
          >
            <v-icon v-if="!item.isCompatible" left v-text="'$warning'" />
            {{ item.gs_logiclib_version || labels.unknown }}
          </v-chip>
        </template>
        <span v-if="!item.isCompatible">{{ labels.incompatible }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.last-comment="{ item }">
      <v-dialog
        v-if="item.numComments"
        scrollable
        width="50vw"
        class="dialog"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="accent"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left v-text="commentIcon(item)" />
          <span class="text-truncate">{{ item.lastComment }}</span>
        </v-btn>
      </template>
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="headline primary white--text">
            Comments for {{ item.name }}
          </v-card-title>
          <v-card-subtitle class="h5 primary white--text">
            {{ backend ? "Backend" : "Frontend" }} version: {{ item.tag }}
          </v-card-subtitle>
          <v-card-text>
            <v-timeline
              align-top
              dense
            >
              <v-timeline-item
                v-for="comment in item.comments"
                :key="comment.modified"
                :color="commentUser(comment) === user ? 'primary' : 'secondary'"
                small
              >
                <div class="font-weight-thin">
                  <strong>{{ commentUser(comment) }}</strong>
                  @ {{ comment.modified || 'past' }} said:
                </div>
                <span class="font-italic">
                  <v-icon v-text="'$commentText'" />
                  {{ comment.text }}
                </span>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialog.value = false"
            >
              {{ labels.close }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    </template>

    <template v-slot:item.certifications="{ item }" >
      <v-chip
        v-for="cert in item.sorted_certifications"
        :key="cert.id"
        label
        :color="cert.background_color"
        :text-color="cert.foreground_color"
        class="certification-chip"
      >
      {{ cert.name }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
/* eslint-disable consistent-return,no-case-declarations */
import {
  get,
  isEmpty,
  orderBy,
} from 'lodash';
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';
import { getMany } from '@/helpers';

export default {
  name: 'versionTable',
  components: {
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
      required: true,
    },
    backend: {
      type: Boolean,
      default: false,
      required: false,
    },
    gameInstances: {
      type: Array,
      default: () => [],
      required: false,
    },
    headers: {
      type: Array,
      default: () => [],
      required: true,
    },
    hidden: {
      type: Number,
      default: 0,
      required: false,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    scale: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapState('fetch', [
      'certificationAuthorities',
    ]),
    isItemSelected() {
      return !isEmpty(this.selectedItem);
    },
    user() {
      return this.$keycloak.userName;
    },
    versionItem() {
      return this.isItemSelected ? this.selectedItem[0] : {};
    },
  },
  data: () => ({
    backendFullVersions: [],
    dataKey: 1,
    expandCsha: false,
    formInput: null,
    labels,
    page: 1,
    replicas: 1,
    search: null,
    selectedItem: [],
  }),
  methods: {
    ...mapActions({
      infoMsg: 'shared/infoMsg',
      setGameInstance: 'shared/setGameInstance',
    }),
    async copyToClipboard(value) { // leave as is
      if (!value) return;
      this.expandCsha = !this.expandCsha;
      if (this.expandCsha) {
        this.infoMsg(this.labels.copiedToClipboard);
        await navigator.clipboard.writeText(value);
        setTimeout(() => { this.expandCsha = false; }, 3000);
      }
    },
    commentIcon(item) {
      return (
        `mdi-numeric-${item.numComments < 10 ? item.numComments : '9-plus'}-box`
      );
    },
    commentUser(comment) {
      return get(comment, 'user.username', 'user');
    },
    dataSort(items, index, isDescending) {
      const sortOrder = isDescending.map((i) => (i ? 'desc' : 'asc'));
      if (index.includes('version')) {
        const arr = items.sort(
          (a, b) => a.version.localeCompare(b.version, undefined, { numeric: true }),
        );
        return orderBy(
          isDescending[0] ? arr.reverse() : arr,
          ['installed'], ['desc'],
        );
      }
      return orderBy(
        items,
        ['installed', ...index],
        ['desc', ...sortOrder],
      );
    },
    disableButton(item, action) {
      if (action.needsSetup && isEmpty(this.getCurrentGameInstance(item))) {
        return true;
      }
      if (action.name === this.labels.deploy) {
        return !isEmpty(this.getCurrentGameInstance(item));
      }
      return (
        (action.needsConsolidation && !this.isConsolidated(item))
        || !this.isItemSelected
        || !action.allowed
      );
    },
    getAssociatedFrontend(item) {
      const gi = this.getCurrentGameInstance(item);
      return get(gi, 'game_frontend_version');
    },
    getCurrentGameInstance(item) {
      if (isEmpty(this.gameInstances)) return;
      const field = (
        this.backend ? 'tag' : 'version'
      );
      const param = (
        `${this.backend ? 'backend_version' : 'frontend_version'}.${field}`
      );
      return this.gameInstances.find(
        (gi) => item[field] === get(gi, param),
      );
    },
    getBackendVersionsForFrontend(frontendVersion) { // verified
      const gameInstances = this.gameInstances.filter(
        (gi) => get(gi, 'game_frontend_version') === frontendVersion,
      );
      return gameInstances.map((gi) => gi.game_backend_version);
    },
    getInstalledLabel(game) {
      const current = this.getCurrentGameInstance(game);
      switch (current.transition) {
        case 'RE':
          return this.labels.restarting;
        case 'RM':
          return this.labels.uninstalling;
        case 'KO':
          return this.labels.failed;
        case 'UP':
          return this.labels.updating;
        default:
          if (!current.consolidated) return this.labels.installing;
          let installed;
          if (!this.backend) {
            const fev = this.getBackendVersionsForFrontend(game.version);
            installed = getMany(fev, 1);
          }
          return installed || this.labels.installed;
      }
    },
    highlightItem(item) {
      const gameInstance = this.getCurrentGameInstance(item);
      if (gameInstance) {
        switch (gameInstance.transition) {
          case 'RE':
            return 'version--restarting';
          case 'RM':
            return 'version--removing';
          case 'KO':
            return 'version--failed';
          case 'UP':
            return 'version--updating';
          default:
            return this.isConsolidated(item)
              ? 'version--consolidated'
              : 'version--highlight';
        }
      } else return '';
    },
    isConsolidated(item) {
      const gameInstance = this.getCurrentGameInstance(item);
      return isEmpty(gameInstance) ? false : gameInstance.consolidated;
    },
    selectRow(item) {
      this.selectedItem = [item];
      const gameInstance = this.getCurrentGameInstance(item);
      this.setGameInstance(gameInstance);
    },
  },
  watch: {
    scale: function scale(val) {
      if (val) {
        this.replicas = get(this.selectedItem[0], 'replicas', 1);
      }
    },
  },
};
</script>

<style scoped>
</style>
