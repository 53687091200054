var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{key:_vm.dataKey,staticClass:"px-3",attrs:{"calculate-widths":"","custom-sort":_vm.dataSort,"dense":"","headers":_vm.headers,"items":_vm.items,"items-per-page":25,"item-class":function (item) { return _vm.highlightItem(item); },"footer-props":{
    itemsPerPageOptions: [10, 25, 50, -1],
  },"page":_vm.page,"search":_vm.search,"single-select":"","sort-by":"version","sort-desc":true},on:{"click:row":_vm.selectRow,"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"5","align-self":"center"}},[_c('span',{staticClass:"font-weight-thin primary--text"},[_vm._v(" "+_vm._s(_vm.labels.actions)+" ")]),_vm._l((_vm.actions),function(action){return _c('span',{key:action.name},[(action.modal && action.allowed)?_c('v-dialog',{staticClass:"dialog",attrs:{"width":"50vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var onDialog = ref.on;
  var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var onTooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-btn",attrs:{"icon":"","color":action.color || 'primary',"small":"","disabled":_vm.disableButton(_vm.versionItem, action)}},'v-btn',attrs,false),Object.assign({}, onTooltip, onDialog)),[_c('v-icon',{attrs:{"color":action.color || 'primary',"dark":""},domProps:{"textContent":_vm._s(action.icon)}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.name))])])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{staticClass:"headline primary white--text"},[_vm._v(" "+_vm._s(action.name)+" ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"prefix":_vm.backend ? _vm.labels.bev : _vm.labels.fev,"value":_vm.versionItem.version,"prepend-icon":'$folder',"readonly":""}}),(action.name === _vm.labels.bev)?_c('div',_vm._l((_vm.getBackendVersionsForFrontend(_vm.versionItem.version)),function(bev,ix){return _c('v-chip',{key:ix,attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(bev)+" ")])}),1):(action.name === _vm.labels.certify)?_c('div',[_c('v-text-field',{attrs:{"label":"CSHA","value":_vm.versionItem.csha,"prepend-icon":'$csha',"messages":_vm.labels.cshaInfo,"filled":"","readonly":""}}),_c('v-select',{attrs:{"items":_vm.certificationAuthorities,"item-text":"name","item-value":"name","label":"Entity","prepend-icon":'$ca'},model:{value:(_vm.formInput),callback:function ($$v) {_vm.formInput=$$v},expression:"formInput"}})],1):_c('v-text-field',{attrs:{"label":_vm.labels.comment,"prepend-icon":'$comment',"placeHolder":"Enter your comment","counter":"","maxlength":"100"},model:{value:(_vm.formInput),callback:function ($$v) {_vm.formInput=$$v},expression:"formInput"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false; _vm.formInput = null}}},[_vm._v(" "+_vm._s(_vm.labels.close)+" ")]),(action.action)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.preventDefault();action.action(
                      _vm.versionItem,
                      action.param,
                      _vm.formInput,
                      _vm.backend
                    ); dialog.value = false; _vm.formInput = null}}},[_vm._v(" "+_vm._s(_vm.labels.confirm)+" ")]):_vm._e()],1)],1)]}}],null,true)}):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"action-btn",attrs:{"icon":"","small":"","color":action.color || 'primary',"disabled":_vm.disableButton(_vm.versionItem, action)},on:{"click":function($event){$event.preventDefault();return action.action(_vm.versionItem, action.param);}}},on),[_c('v-icon',{attrs:{"color":action.color || 'primary',"dark":""},domProps:{"textContent":_vm._s(action.icon)}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.name))])])],1)}),_c('v-slider',{directives:[{name:"show",rawName:"v-show",value:(_vm.scale),expression:"scale"}],attrs:{"hint":_vm.labels.replicas,"persistent-hint":"","thumb-color":"accent","thumb-label":"always","align-items":"baseline","min":"1","max":"10"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","outlined":"","text":""},on:{"click":function($event){return _vm.$emit('scale', _vm.replicas)}}},[_vm._v(" "+_vm._s(_vm.labels.apply)+" ")])]},proxy:true}]),model:{value:(_vm.replicas),callback:function ($$v) {_vm.replicas=$$v},expression:"replicas"}})],2),_c('v-spacer'),_c('v-text-field',{staticClass:"col-12 col-md-7 ma-0",attrs:{"prepend-icon":'$search',"label":"Filter","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"header.lglib-version",fn:function(ref){
                    var header = ref.header;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{staticClass:"mx-1",attrs:{"color":"primary"},domProps:{"textContent":_vm._s(_vm.hidden ? '$incompatible' : '$iris')},on:{"click":function($event){$event.preventDefault();return _vm.$emit('toggle-incompatible')}}}),(_vm.hidden)?_c('v-chip',{attrs:{"color":"yellow","label":"","small":"","pill":""}},[_vm._v(" "+_vm._s(_vm.hidden)+" hidden ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.labels.showCompatible))])])]}},{key:"item.version",fn:function(ref){
                    var item = ref.item;
return [(_vm.backend)?_c('span',[_vm._v(_vm._s(item.build)+"-")]):_vm._e(),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.version))]),(_vm.highlightItem(item))?_c('v-badge',{attrs:{"content":_vm.getInstalledLabel(item),"color":"secondary","inline":""}}):_vm._e()]}},{key:"item.frontend",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAssociatedFrontend(item))+" ")]}},{key:"item.csha",fn:function(ref){
                    var value = ref.value;
return [_c('v-chip',{attrs:{"color":value ? 'accent' : 'error',"dark":"","label":"","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.copyToClipboard(value)}}},[(!value)?_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('$warning')}}):_vm._e(),_c('span',{ref:"textToCopy",class:_vm.expandCsha ? '' : 'text-truncate'},[_vm._v(" "+_vm._s(value ? value : _vm.labels.unknown)+" ")])],1)]}},{key:"item.lglib-version",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":item.isCompatible ? 'accent' : 'warning',"dark":"","label":"","outlined":""}},'v-chip',attrs,false),on),[(!item.isCompatible)?_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('$warning')}}):_vm._e(),_vm._v(" "+_vm._s(item.gs_logiclib_version || _vm.labels.unknown)+" ")],1)]}}],null,true)},[(!item.isCompatible)?_c('span',[_vm._v(_vm._s(_vm.labels.incompatible))]):_vm._e()])]}},{key:"item.last-comment",fn:function(ref){
                    var item = ref.item;
return [(item.numComments)?_c('v-dialog',{staticClass:"dialog",attrs:{"scrollable":"","width":"50vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(_vm.commentIcon(item))}}),_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.lastComment))])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{staticClass:"headline primary white--text"},[_vm._v(" Comments for "+_vm._s(item.name)+" ")]),_c('v-card-subtitle',{staticClass:"h5 primary white--text"},[_vm._v(" "+_vm._s(_vm.backend ? "Backend" : "Frontend")+" version: "+_vm._s(item.tag)+" ")]),_c('v-card-text',[_c('v-timeline',{attrs:{"align-top":"","dense":""}},_vm._l((item.comments),function(comment){return _c('v-timeline-item',{key:comment.modified,attrs:{"color":_vm.commentUser(comment) === _vm.user ? 'primary' : 'secondary',"small":""}},[_c('div',{staticClass:"font-weight-thin"},[_c('strong',[_vm._v(_vm._s(_vm.commentUser(comment)))]),_vm._v(" @ "+_vm._s(comment.modified || 'past')+" said: ")]),_c('span',{staticClass:"font-italic"},[_c('v-icon',{domProps:{"textContent":_vm._s('$commentText')}}),_vm._v(" "+_vm._s(comment.text)+" ")],1)])}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" "+_vm._s(_vm.labels.close)+" ")])],1)],1)]}}],null,true)}):_vm._e()]}},{key:"item.certifications",fn:function(ref){
                    var item = ref.item;
return _vm._l((item.sorted_certifications),function(cert){return _c('v-chip',{key:cert.id,staticClass:"certification-chip",attrs:{"label":"","color":cert.background_color,"text-color":cert.foreground_color}},[_vm._v(" "+_vm._s(cert.name)+" ")])})}}]),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})}
var staticRenderFns = []

export { render, staticRenderFns }